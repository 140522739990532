import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  ChartDataPoint,
  FeedChartDataPoint,
  PriceChartDataPoint,
  MetricsChartType,
  FavoriteList,
  Mall,
  MetricsComparison,
  ProductInfo,
  User,
  ContentChartDataPoint,
  ContentStats,
  Feed,
  FeedTypes,
  Country,
  ProductReview,
  ProductReviewFilter,
  ReviewsByStar,
  ReviewsByCountry,
  TimelineEvent,
  BrandGroup,
  ExportList,
  Subscription,
  ProductOffers,
  AutocompleteSuggestion,
  CategoryTree,
  ProductListFilter,
  ProductListItem,
  Category,
  FilterPreset,
  FilterPresetType,
  FilterPresetInfo,
  ISO8601DateString,
  ProductDescriptionVersion,
  StartExportResponse,
  BusinessApiKey,
  BusinessApiKeyInfo,
  BusinessSalesStats,
  BusinessSalesLostProductStats,
  BusinessSalesProductList,
  BusinessSalesBrand,
  BusinessReviewsStopword,
  BusinessReviewsTemplate,
  BusinessReviewsRule,
  BusinessReviewsProduct,
  BusinessReviewsBrand,
  BusinessReviewsOnboarding,
  BusinessReviewsStopwordFilter,
  BusinessReviewsTemplateFilter,
  BusinessReviewsRuleFilter,
  NewUser,
  FormValidationErrors,
  PromoCode,
  CategoryListFilter,
  CategoryStatBrand,
  CategoryStatMerchant,
  CategoryPriceRange,
  ListingDTO,
  CategoryStatCharts,
  CategoryList,
  CategoryStatChartsQuery,
  BusinessSalesProductListFilter,
  OfferChartType,
  PrimeCostResponse,
  KeywordListFilter,
  Keyword,
  KeywordBrand,
  KeywordMerchant,
  AiFeatureName,
  FeedType,
  ReverseFeedTypes,
  QuantileResponse,
  QuantileRequest,
  SellersWorkScheme,
  SubscriptionTariffs,
  SubscriptionRequest,
  SubscriptionResponse,
  BusinessReviewsAccountInfo,
  RegisterResponse,
  RegisterSendAuthCodeResponse,
  LoginData,
  LoginResponse,
  LoginSubmitAuthCodeResponse,
  RecoverySendAuthCodeResponse,
  RecoveryResetPasswordResponse,
  CompanyContactRequest,
  BLArticleList,
  BLArticle,
  PersonContactRequest,
  WarehouseChartType,
  BidderAccountInfo,
  BidderCampaignInfo,
  BidderCampaignListFilter,
  BidderCampaignListItem,
  BidderCampaignStat,
  BidderCampaignBidderStat,
  BidderCampaignBidDistribution,
  VariantChartType,
  ProductListItemsSummary,
  BidderCampaignUpdateInfo,
  BidderCampaignKeywords,
  BidderCampaignKeywordsUpdate,
  BidderCampaignBidDistributionRequest,
  BidderRegion,
  BidderProductCurrentPosition,
  KeywordStatValue,
  BidderCampaignProduct,
  BidderCampaignBidPrediction,
  BusinessSalesChartData,
  SupplyRecommendationReport,
  SupplyRecommendationRequest,
  SupplyRecommendationSettings,
  SupplyRecommendationAvailableSettings,
  ProductNameVersion,
  ProductVariationAggregates,
  FeedChartPositionsRequest,
  FeedChartPositions,
  SubjectTree,
  Subject,
  MarketplaceCommission,
  WarehouseCoefficient,
  BidderSyncResponse,
  BidderCampaignStatSummary,
} from 'lib/dto'
import { ContextDTO } from 'lib/contextCreation'
import { Theme } from '../configs/theme'
import { ActionpayAction, actionpayGoalIds } from 'lib/dto'

export class Context {
  isAuthorized: boolean
  malls: Mall[]
  currentMall: Mall
  theme: Theme
  user: User
  ip: string
  userAgent: string
  private cookie: string | null
  private api: AxiosInstance

  constructor(ctx: ContextDTO, api?: AxiosInstance) {
    this.isAuthorized = ctx.isAuthorized
    this.currentMall = ctx.currentMall
    this.malls = ctx.malls
    this.theme = ctx.theme
    this.user = ctx.user as User
    this.cookie = ctx.cookie
    this.ip = ctx.ip
    this.userAgent = ctx.userAgent

    if (api) {
      this.api = api
      return
    }

    const isServerSide = typeof window === 'undefined'

    const apiBaseURL = isServerSide
      ? `${
          ctx.host.includes('localhost') || ctx.host.match(/\d/) || process.env.FORCE_HTTP === '1'
            ? 'http'
            : 'https'
        }://${ctx.host}/api/next/`
      : '/api/next/'

    this.api = axios.create({
      baseURL: apiBaseURL,
      headers: {
        'x-auth': ctx.isAuthorized,
        ...(isServerSide && ctx.cookie && { cookie: ctx.cookie }),
        ...(isServerSide && ctx.ip && { 'x-forwarded-for': ctx.ip }),
        ...(isServerSide && ctx.userAgent && { 'user-agent': ctx.userAgent }),
      },
      withCredentials: true,
      validateStatus: (status) => (status >= 200 && status < 300) || status === 429,
    })
  }

  private get = async <TResponse>(strings: TemplateStringsArray, ...params: any[]) => {
    const route = strings.reduce((acc, val, i) => acc + val + (params[i] ?? ''), '')

    const apiResponse = await this.api.get<TResponse>(route)

    return apiResponse.data
  }

  private post = <TResponse>(strings: TemplateStringsArray, ...params: any[]) => {
    const route = strings.reduce((acc, val, i) => acc + val + (params[i] ?? ''), '')

    return async (data?: object, config?: AxiosRequestConfig) => {
      const apiResponse = await this.api.post<object, AxiosResponse<TResponse>>(route, data, config)
      return apiResponse.data
    }
  }

  getCurrentUser = async () => {
    const res = await this.api.get<User>('/user/current/', {
      headers: { ...(this.cookie && { Cookie: this.cookie }), 'x-auth': true },
    })

    return [res.data, res.headers['set-cookie'] as string | string[]] as const
  }

  getMallList = () => this.get<Mall[]>`/mall/list/`

  getMallInfo = (code: string) => this.get<Mall>`/mall/${code}/info/`

  getProductInfo = (mall: string, id: string) => this.get<ProductInfo>`/product/${mall}/info/${id}/`

  getFavoriteLists = () => this.get<FavoriteList[]>`/favorites/${this.currentMall.code}/lists/`

  getFavoriteListsForProduct = (id: number) =>
    this.get<FavoriteList[]>`/favorites/${this.currentMall.code}/product/${id}/lists/`

  createFavoriteList = (name: string) =>
    this.post<FavoriteList>`/favorites/${this.currentMall.code}/list/add/`({ name })

  renameFavoriteList = (id: number, name: string) =>
    this.post<FavoriteList>`/favorites/${this.currentMall.code}/list/${id}/rename/`({ name })

  deleteFavoriteList = (id: number) =>
    this.post<FavoriteList>`/favorites/${this.currentMall.code}/list/${id}/delete/`({})

  addToFavoriteList = (listId: number, id: number) =>
    this.post<FavoriteList>`/favorites/${this.currentMall.code}/list/${listId}/product/add/`({
      productId: id,
    })

  removeFromFavoriteList = (listId: number, id: number) =>
    this.post<FavoriteList>`/favorites/${this.currentMall.code}/list/${listId}/product/remove/`({
      productId: id,
    })

  getFavoriteListProducts = (id: number, filter: ProductListFilter, abortSignal?: AbortSignal) =>
    this.post<
      ListingDTO<ProductListItem>
    >`/favorites/${this.currentMall.code}/list/${id}/products/list/`(filter, {
      signal: abortSignal,
    })

  startFavoriteListProductsExport = (id: number, filter: ProductListFilter) =>
    this
      .post<StartExportResponse>`/favorites/${this.currentMall.code}/list/${id}/products/list/export/`(
      filter
    )

  getMetricsAggregate = (id: number, period: number) =>
    this
      .get<MetricsComparison>`/product/${this.currentMall.code}/aggregations-period/${id}/days/${period}/`

  getProductMetricsAggregate = ({
    productId,
    from,
    to,
  }: {
    productId: number
    from?: ISO8601DateString
    to?: ISO8601DateString
  }) =>
    this.post<MetricsComparison>`/product/${this.currentMall.code}/aggregations/${productId}/`({
      from: from ?? null,
      to: to ?? null,
    })

  getVariationAggregates = ({
    productId,
    from,
    to,
  }: {
    productId: number
    from?: ISO8601DateString
    to?: ISO8601DateString
  }) =>
    this.post<
      ProductVariationAggregates[]
    >`/product/${this.currentMall.code}/variations-aggregations/${productId}/`({
      from: from ?? null,
      to: to ?? null,
    })

  getChartData = <T extends MetricsChartType>(
    id: number,
    chartType: T,
    from?: ISO8601DateString,
    to?: ISO8601DateString
  ) =>
    this.post<
      T extends 'prices' ? PriceChartDataPoint[] : ChartDataPoint[]
    >`/product/${this.currentMall.code}/single-chart/${id}/${chartType}/`({ from, to })

  getOfferChartData = <T extends OfferChartType>({
    variationId,
    merchantId,
    chartType,
  }: {
    variationId: number
    merchantId: number
    chartType: T
  }) =>
    this.post<
      ChartDataPoint[]
    >`/product/${this.currentMall.code}/offer-chart/${variationId}/${merchantId}/${chartType}/`({})

  getWarehouseChartData = <T extends WarehouseChartType>({
    productId,
    warehouseId,
    chartType,
    variationId,
    from,
    to,
  }: {
    productId: number
    chartType: T
    warehouseId?: string
    variationId?: number
    from?: ISO8601DateString
    to?: ISO8601DateString
  }) =>
    this.post<
      ChartDataPoint[]
    >`/product/${this.currentMall.code}/warehouse-chart/${productId}/${chartType}/`({
      from: from ?? null,
      to: to ?? null,
      variationId: variationId ?? null,
      warehouseId: warehouseId ?? null,
    })

  getVariantChartData = <T extends VariantChartType>({
    productId,
    variationId,
    chartType,
    from,
    to,
  }: {
    productId: number
    variationId: number
    chartType: T
    from?: ISO8601DateString
    to?: ISO8601DateString
  }) =>
    this.post<
      ChartDataPoint[]
    >`/product/${this.currentMall.code}/variation-chart/${productId}/${variationId}/${chartType}/`({
      from: from ?? null,
      to: to ?? null,
    })

  getContentChart = (id: number) =>
    this.post<ContentChartDataPoint[]>`/product/${this.currentMall.code}/content/${id}/chart/`({})

  getContentStats = (id: number) =>
    this.get<ContentStats>`/product/${this.currentMall.code}/content/${id}/stat/`

  getFeedsList = (id: number) =>
    this.get<Feed[]>`/product/${this.currentMall.code}/feeds/${id}/list/`

  getFeedChart = (
    productId: number,
    feedId: number,
    feedType: keyof typeof FeedTypes,
    from?: ISO8601DateString,
    to?: ISO8601DateString
  ) =>
    this.post<
      FeedChartDataPoint[]
    >`/product/${this.currentMall.code}/feeds/${productId}/feed/${feedId}/${feedType}/chart/`(
      from && to
        ? {
            from,
            to,
          }
        : {}
    )

  getFeedChartPositions = (productId: number, request: FeedChartPositionsRequest) =>
    this.post<FeedChartPositions[]>`/product/${this.currentMall.code}/feeds/${productId}/charts/`(
      request
    )

  getFeedProducts = (
    feedType: FeedType,
    feedId: number,
    filter: ProductListFilter,
    signal: AbortSignal
  ) =>
    this.post<
      ListingDTO<ProductListItem> & { feedId: number }
    >`/feeds/${this.currentMall.code}/${feedId}/${ReverseFeedTypes[feedType]}/products/`(filter, {
      signal,
    })

  getCountryList = () => this.get<Country[]>`/countries/${this.currentMall.code}/list/`

  getReviews = (productId: number, filter: ProductReviewFilter = {}) =>
    this.post<ProductReview[]>`/product/${this.currentMall.code}/reviews/${productId}/list/`(filter)

  getReviewsByStar = (productId: number) =>
    this.get<ReviewsByStar[]>`/product/${this.currentMall.code}/reviews/${productId}/distribution/`

  getReviewsByCountry = (productId: number) =>
    this.get<ReviewsByCountry[]>`/product/${this.currentMall.code}/reviews/${productId}/geo/list/`

  getProductTimeline = (productId: number) =>
    this.get<TimelineEvent[]>`/product/${this.currentMall.code}/events/${productId}/`

  updateUserFullname = (name: string) => this.post<User>`/user/update-info/`({ fullName: name })

  updateUserPassword = (oldPassword: string, newPassword: string) =>
    this.post`/user/change-password/`({
      oldPassword,
      newPassword,
    })

  getBrandGroups = () => this.get<BrandGroup[]>`/user/brands-groups/`

  getUserSubscription = () => this.get<Subscription>`/user/subscription/active/`

  setUserSubscription = (subscriptionId: number, mallId: number, newState: boolean) =>
    this.post`/subscription/${subscriptionId}/mall/${mallId}/renew/${
      newState ? 'enable' : 'disable'
    }/`({})

  getUserTariffs = () => this.get<SubscriptionTariffs>`/subscription/tariffs/`

  relinkCard = (subscriptionId: number) =>
    this.post<{ url: string }>`/subscription/${subscriptionId}/relink-card/`({})

  createSubscription = (request: SubscriptionRequest) =>
    this.post<SubscriptionResponse>`/subscription/create/`(request)

  requestSubscriptionPayment = (request: SubscriptionRequest) =>
    this.post<[]>`/subscription/request-payment-on-account/`(request)

  getExportList = (page: number, pageSize: number) =>
    this.get<ExportList>`/reports/list/?page=${page}&pageSize=${pageSize}`

  removeReport = (id: number) => this.post`/reports/${id}/hide/`({})

  getProductOffers = (id: number) =>
    this.get<ProductOffers>`/product/${this.currentMall.code}/offers/${id}/`

  getEntityNamesByIds = <T extends 'brands' | 'merchants' | 'categories' | 'subjects'>(
    entity: T,
    ids: number[],
    abortSignal?: AbortSignal
  ) =>
    this.post<
      T extends 'categories'
        ? Category[]
        : T extends 'subjects'
        ? Subject[]
        : AutocompleteSuggestion[]
    >`/${entity}/${this.currentMall.code}/ids/`(
      {
        ids,
      },
      { signal: abortSignal }
    )

  getNameSuggestion = (
    entity: 'brands' | 'merchants' | 'categories',
    name?: string,
    limit?: number
  ) =>
    this.post<AutocompleteSuggestion[]>`/${entity}/${this.currentMall.code}/suggest/`({
      name,
      limit,
    })

  getProductList = (mall: string, filter: ProductListFilter, abortSignal?: AbortSignal) =>
    this.post<ListingDTO<ProductListItem>>`/products/${mall}/list/`(filter, {
      signal: abortSignal,
    })

  getProductListItemsSummary = (filter: ProductListFilter, abortSignal?: AbortSignal) =>
    this.post<ProductListItemsSummary>`/products/${this.currentMall.code}/list/summary/`(filter, {
      signal: abortSignal,
    })

  getPresetsByType = (type: FilterPresetType) =>
    this.get<FilterPresetInfo[]>`/presets/${this.currentMall.code}/list/${type}/`

  getFilterPreset = (id: number) => this.get<FilterPreset>`/presets/${id}/`

  addFilterPreset = (name: string, type: FilterPresetType, data: string) =>
    this.post<FilterPreset>`/presets/${this.currentMall.code}/add/`({ name, type, data })

  deleteFilterPreset = (id: number) => this.post<[]>`/presets/${id}/delete/`({})

  getProductNameVersions = (id: number) =>
    this.get<ProductNameVersion[]>`/product/${this.currentMall.code}/name/${id}/versions/`

  getProductDescriptionVersions = (id: number) =>
    this.get<string[]>`/product/${this.currentMall.code}/description/${id}/versions/`

  getProductDescription = (id: number, versions: string[]) =>
    this.post<
      ProductDescriptionVersion[]
    >`/product/${this.currentMall.code}/description/${id}/texts/`({ versions })

  //
  //
  // EXPORTS METHODS
  //

  startProductListExport = (mall: string, filter: ProductListFilter) =>
    this.post<StartExportResponse>`/products/${mall}/list/export/`(filter)

  startFeedListExport = (productId: number) =>
    this
      .post<StartExportResponse>`/product/${this.currentMall.code}/feeds/${productId}/list/export/`(
      {}
    )

  startFeedExport = (productId: number, feedId: number, feedType: number) =>
    this
      .post<StartExportResponse>`/product/${this.currentMall.code}/feeds/${productId}/feed/${feedId}/${feedType}/chart/export/`(
      {}
    )

  startReviewsExport = (productId: number, filter: ProductReviewFilter = {}) =>
    this
      .post<StartExportResponse>`/product/${this.currentMall.code}/reviews/${productId}/list/export/`(
      filter
    )

  startChartsExport = (productId: number) =>
    this.post<StartExportResponse>`/product/${this.currentMall.code}/charts/${productId}/export/`(
      {}
    )

  startOffersExport = (productId: number) =>
    this.post<StartExportResponse>`/product/${this.currentMall.code}/offers/${productId}/export/`(
      {}
    )

  startOfferChartExport = (variantId: number, merchantId: number) =>
    this
      .post<StartExportResponse>`/product/${this.currentMall.code}/offer-charts/${variantId}/${merchantId}/export/`(
      {}
    )

  startWarehouseChartExport = ({
    productId,
    warehouseId,
    variationId,
    from,
    to,
  }: {
    productId: number
    warehouseId?: string
    variationId?: number
    from?: ISO8601DateString
    to?: ISO8601DateString
  }) =>
    this
      .post<StartExportResponse>`/product/${this.currentMall.code}/warehouse-charts/${productId}/export/`(
      {
        from: from ?? null,
        to: to ?? null,
        variationId: variationId ?? null,
        warehouseId: warehouseId ?? null,
      }
    )

  startEventsExport = (productId: number) =>
    this.post<StartExportResponse>`/product/${this.currentMall.code}/events/${productId}/export/`(
      {}
    )

  startBusinessProductListExport = (
    keyId: number,
    filter: BusinessSalesProductListFilter,
    sellersWorkScheme: SellersWorkScheme
  ) =>
    this
      .post<StartExportResponse>`/mall-analytics/dashboard/${this.currentMall.code}/${keyId}/list/export/`(
      { ...filter, sellersWorkScheme }
    )

  startCategoryStatExport = (mall: string, filter: CategoryListFilter) =>
    this.post<StartExportResponse>`/category-stat/${mall}/list/export/`({
      from: filter.from,
      to: filter.to,
      categoryId: filter.categoryId,
      brandsIds: filter.brandsIds,
      merchantsIds: filter.merchantsIds,
    })

  startKeywordListExport = (filter: KeywordListFilter) =>
    this.post<StartExportResponse>`/keywords/${this.currentMall.code}/list/export/`(filter)

  startKeywordProductListExport = (feedId: number, feedType: 1 | 2 | 3 | 4 | 6) =>
    this
      .post<StartExportResponse>`/feeds/${this.currentMall.code}/${feedId}/${feedType}/products/export/`(
      {}
    )

  //
  //
  // CATEGORIES METHODS
  //

  getCategoryTree = () => this.get<CategoryTree[]>`/categories/${this.currentMall.code}/tree/`

  getSubjectTree = () => this.get<SubjectTree[]>`/subjects/${this.currentMall.code}/tree/`

  getCategoriesIds = (ids: number[]) =>
    this.post<CategoryTree[]>`/categories/${this.currentMall.code}/ids/`({ ids })

  getCategoriesByBrands = (brands: number[]) =>
    this.post<number[]>`/categories/${this.currentMall.code}/brands-categories/`({ ids: brands })

  getCategoriesLastUpdated = () =>
    this.get<{
      lastDate: ISO8601DateString | null
    }>`/category-stat/${this.currentMall.code}/last-date/`

  getCategoryStats = (mall: string, filter: CategoryListFilter, abortSignal?: AbortSignal) =>
    this.post<CategoryList>`/category-stat/${mall}/list/`(
      {
        from: filter.from,
        to: filter.to,
        categoryId: filter.categoryId,
        brandsIds: filter.brandsIds,
        merchantsIds: filter.merchantsIds,
        sort: 'gmv_desc',
      },
      {
        signal: abortSignal,
      }
    )

  getCategoryStatBrands = (categoryId: number, filter: CategoryListFilter, sort: string) =>
    this.post<{
      brands: CategoryStatBrand[]
    }>`/category-stat/${this.currentMall.code}/${categoryId}/brands/`({
      from: filter.from,
      to: filter.to,
      brandsIds: filter.brandsIds,
      merchantsIds: filter.merchantsIds,
      sort: sort,
      limit: 100,
    })

  getCategoryStatMerchants = (categoryId: number, filter: CategoryListFilter, sort: string) =>
    this.post<{
      merchants: CategoryStatMerchant[]
    }>`/category-stat/${this.currentMall.code}/${categoryId}/merchants/`({
      from: filter.from,
      to: filter.to,
      brandsIds: filter.brandsIds,
      merchantsIds: filter.merchantsIds,
      sort: sort,
      limit: 100,
    })

  getCategoryStatCharts = (query: CategoryStatChartsQuery) =>
    this.post<CategoryStatCharts>`/category-stat/${this.currentMall.code}/chart/`(query)

  getCategoryPriceDistribution = (categoryId: number, filter: CategoryListFilter) =>
    this.post<
      CategoryPriceRange[]
    >`/category-stat/${this.currentMall.code}/${categoryId}/price-distribution/`({
      from: filter.from,
      to: filter.to,
      brandsIds: filter.brandsIds,
      merchantsIds: filter.merchantsIds,
    })

  //
  //
  // MERCHANT AND BRAND LIST METHODS
  //

  getBrandStatList = (filter: CategoryListFilter, signal: AbortSignal) =>
    this.post<ListingDTO<CategoryStatBrand>>`/brand-stat/${this.currentMall.code}/list/`(filter, {
      signal,
    })

  getMerchantStatList = (filter: CategoryListFilter, signal: AbortSignal) =>
    this.post<ListingDTO<CategoryStatMerchant>>`/merchant-stat/${this.currentMall.code}/list/`(
      filter,
      { signal }
    )

  startBrandStatListExport = (filter: CategoryListFilter) =>
    this.post<StartExportResponse>`/brand-stat/${this.currentMall.code}/list/export/`(filter)

  startMerchantStatListExport = (filter: CategoryListFilter) =>
    this.post<StartExportResponse>`/merchant-stat/${this.currentMall.code}/list/export/`(filter)

  getBrandStatChart = (query: CategoryStatChartsQuery) =>
    this.post<CategoryStatCharts>`/brand-stat/${this.currentMall.code}/chart/`(query)

  getMerchantStatChart = (query: CategoryStatChartsQuery) =>
    this.post<CategoryStatCharts>`/merchant-stat/${this.currentMall.code}/chart/`(query)

  //
  //
  // KEYWORDS METHODS
  //

  getKeywordList = (filter: KeywordListFilter, signal: AbortSignal) =>
    this.post<ListingDTO<Keyword>>`/keywords/${this.currentMall.code}/list/`(filter, { signal })

  getKeywordBrands = (feedId: number) =>
    this.get<KeywordBrand[]>`/keywords/${this.currentMall.code}/brands/${feedId}/`

  getKeywordMerchants = (feedId: number) =>
    this.get<KeywordMerchant[]>`/keywords/${this.currentMall.code}/merchants/${feedId}/`

  getKeywordQuantiles = () =>
    this.post<QuantileResponse>`/keywords/${this.currentMall.code}/quantiles/`({
      metrics: ['competition'],
      quantiles: [0.05, 0.1, 0.2, 0.5],
    } as QuantileRequest)

  getKeywordCharts = (feedId: number) =>
    this.get<KeywordStatValue[]>`/keywords/${this.currentMall.code}/${feedId}/charts/`

  //
  //
  // BUSINESS API-KEYS METHODS
  //

  getBusinessAccounts = () =>
    this.get<BusinessApiKey[]>`/mall-analytics/account/${this.currentMall.code}/list/`

  getBusinessAccountInfo = (apiKeyId: number) =>
    this.get<BusinessApiKeyInfo>`/mall-analytics/account/${this.currentMall.code}/${apiKeyId}/info/`

  getBusinessAccountInfoList = () =>
    this.get<BusinessApiKeyInfo[]>`/mall-analytics/account/${this.currentMall.code}/list-extra/`

  creteBusinessAccount = (value: BusinessApiKey) =>
    this.post<BusinessApiKey>`/mall-analytics/account/${this.currentMall.code}/create/`({
      name: value.name,
      key: value.key,
      inn: value.inn,
      marketplaceSellerId: value.marketplaceSellerId,
    })

  updateBusinessAccount = (value: BusinessApiKey) =>
    this.post<BusinessApiKey>`/mall-analytics/account/${this.currentMall.code}/${value.id}/update/`(
      {
        name: value.name,
        inn: value.inn,
      }
    )

  deleteBusinessAccount = (apiKeyId: number) =>
    this.post`/mall-analytics/account/${this.currentMall.code}/${apiKeyId}/delete/`({})

  changeBusinessAccountApiKey = (apiKeyId: number, newKey: string) =>
    this
      .post<BusinessApiKey>`/mall-analytics/account/${this.currentMall.code}/${apiKeyId}/change-api-key/`(
      { newKey }
    )

  createBusinessReviewsApiKey = (apiKeyId: number, key: string) =>
    this
      .post<BusinessApiKey>`/autoreplier/account/${this.currentMall.code}/${apiKeyId}/create-api-key/`(
      { key }
    )

  changeBusinessReviewsApiKey = (apiKeyId: number, newKey: string) =>
    this
      .post<BusinessApiKey>`/autoreplier/account/${this.currentMall.code}/${apiKeyId}/change-api-key/`(
      { newKey }
    )

  deleteBusinessReviewsApiKey = (apiKeyId: number) =>
    this
      .post<BusinessApiKey>`/autoreplier/account/${this.currentMall.code}/${apiKeyId}/delete-api-key/`(
      {}
    )

  enableBusinessReviews = (apiKeyId: number) =>
    this.post<BusinessApiKey>`/autoreplier/account/${this.currentMall.code}/${apiKeyId}/enable/`({})

  disableBusinessReviews = (apiKeyId: number) =>
    this.post<BusinessApiKey>`/autoreplier/account/${this.currentMall.code}/${apiKeyId}/disable/`(
      {}
    )

  getBusinessReviewsAccountInfo = (apiKeyId: number) =>
    this
      .get<BusinessReviewsAccountInfo>`/autoreplier/account/${this.currentMall.code}/${apiKeyId}/info/`

  getBusinessProductList = (apiKeyId: number, name?: string) =>
    this.get<BusinessReviewsProduct[]>`/mall-analytics/dashboard/${
      this.currentMall.code
    }/${apiKeyId}/products/?name=${name ?? ''}`

  getBusinessReviewsBrands = (apiKeyId: number) =>
    this.get<BusinessReviewsBrand[]>`/autoreplier/brand/${this.currentMall.code}/${apiKeyId}/list/`

  //
  //
  // BUSINESS SALES METHODS
  //

  getBusinessSalesStats = (
    id: number,
    filter: { from: string; to: string; brandId: number[]; sellersWorkScheme: SellersWorkScheme }
  ) =>
    this.post<BusinessSalesStats>`/mall-analytics/dashboard/${this.currentMall.code}/${id}/stats/`(
      filter
    )

  getBusinessSalesChartData = (
    id: number,
    filter: { from: string; to: string; brandId: number[]; sellersWorkScheme: SellersWorkScheme }
  ) =>
    this.post<
      BusinessSalesChartData[]
    >`/mall-analytics/dashboard/${this.currentMall.code}/${id}/graph/`(filter)

  getBusinessSalesLostProductStats = (
    id: number,
    brandId: number[],
    sellersWorkScheme: SellersWorkScheme
  ) =>
    this.post<
      BusinessSalesLostProductStats[]
    >`/mall-analytics/dashboard/${this.currentMall.code}/${id}/products/lost-stats/`({
      brandId,
      sellersWorkScheme,
    })

  getBusinessSalesProducts = (
    id: number,
    filter: BusinessSalesProductListFilter,
    sellersWorkScheme: SellersWorkScheme
  ) =>
    this
      .post<BusinessSalesProductList>`/mall-analytics/dashboard/${this.currentMall.code}/${id}/list/`(
      { ...filter, sellersWorkScheme }
    )

  getBusinessSalesBrands = (apiKeyId: number) =>
    this.get<
      BusinessSalesBrand[]
    >`/mall-analytics/dashboard/${this.currentMall.code}/${apiKeyId}/brands/`

  checkNullPrimeCosts = (
    apiKeyId: number,
    date: { from: ISO8601DateString; to: ISO8601DateString }
  ) =>
    this.post<{
      hasNullPrimeCosts: boolean
    }>`/mall-analytics/prime-cost/${this.currentMall.code}/${apiKeyId}/check/`(date)

  updatePrimeCost = ({
    apiKeyId,
    productSku,
    primeCost,
    size,
  }: {
    apiKeyId: number
    productSku: string
    primeCost: number
    size?: string
  }) =>
    this
      .post<PrimeCostResponse>`/mall-analytics/prime-cost/${this.currentMall.code}/${apiKeyId}/article/${productSku}/update/`(
      { primeCost, size }
    )

  deletePrimeCost = ({
    apiKeyId,
    productSku,
    size,
  }: {
    apiKeyId: number
    productSku: string
    size?: string
  }) =>
    this.post<
      []
    >`/mall-analytics/prime-cost/${this.currentMall.code}/${apiKeyId}/article/${productSku}/delete/`(
      { size }
    )

  deleteAllPrimeCosts = (apiKeyId: number) =>
    this.post<[]>`/mall-analytics/prime-cost/${this.currentMall.code}/${apiKeyId}/delete-all/`({})

  uploadPrimeCostFile = (apiKeyId: number, file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    return this.api.post<[] | FormValidationErrors>(
      `/mall-analytics/prime-cost/${this.currentMall.code}/${apiKeyId}/upload/`,
      formData
    )
  }

  //
  //
  // BUSINESS STOPWORDS METHODS
  //

  getBusinessReviewsStopwordList = (sort: string, filter: BusinessReviewsStopwordFilter) =>
    this.post<BusinessReviewsStopword[]>`/autoreplier/stopword/${this.currentMall.code}/list-all/`({
      sort,
      text: filter.text,
    })

  getBusinessReviewsStopwordListForApiKey = (sort: string, filter: BusinessReviewsStopwordFilter) =>
    this.post<
      BusinessReviewsStopword[]
    >`/autoreplier/stopword/${this.currentMall.code}/${filter.apiKeyId}/list/`({
      sort,
      text: filter.text,
    })

  createBusinessReviewsStopword = (value: BusinessReviewsStopword, list: string[]) =>
    this.post`/autoreplier/stopword/${this.currentMall.code}/${value.apiKeyId}/create/`({
      list: list,
    })

  updateBusinessReviewsStopword = (apiKeyId: number, value: BusinessReviewsStopword) =>
    this.post`/autoreplier/stopword/${this.currentMall.code}/${apiKeyId}/${value.id}/update/`({
      text: value.text,
      apiKeyId: value.apiKeyId,
      mall: this.currentMall.code,
    })

  daleteBusinessReviewsStopword = (apiKeyId: number, stopwordId: number) =>
    this.post`/autoreplier/stopword/${this.currentMall.code}/${apiKeyId}/${stopwordId}/delete/`({})

  //
  //
  // BUSINESS TEMPLATES METHODS
  //

  getBusinessReviewsTemplateList = (sort: string, filter: BusinessReviewsTemplateFilter) =>
    this.post<BusinessReviewsTemplate[]>`/autoreplier/template/${this.currentMall.code}/list-all/`({
      sort,
      title: filter.title,
      productsId: filter.productsId,
      brandsId: filter.brandsId,
    })

  getBusinessReviewsTemplateListForApiKey = (sort: string, filter: BusinessReviewsTemplateFilter) =>
    this.post<
      BusinessReviewsTemplate[]
    >`/autoreplier/template/${this.currentMall.code}/${filter.apiKeyId}/list/`({
      sort,
      title: filter.title,
      productsId: filter.productsId,
      brandsId: filter.brandsId,
    })

  createBusinessReviewsTemplate = (value: BusinessReviewsTemplate) =>
    this.post`/autoreplier/template/${this.currentMall.code}/${value.apiKeyId}/create/`({
      title: value.title,
      text: value.text,
      productsId: value.productsId,
      brandsId: value.brandsId,
    })

  updateBusinessReviewsTemplate = (apiKeyId: number, value: BusinessReviewsTemplate) =>
    this.post`/autoreplier/template/${this.currentMall.code}/${apiKeyId}/${value.id}/update/`({
      title: value.title,
      text: value.text,
      apiKeyId: value.apiKeyId,
      productsId: value.productsId,
      brandsId: value.brandsId,
      mall: this.currentMall.code,
    })

  deleteBusinessReviewsTemplate = (apiKeyId: number, templatedId: number) =>
    this.post`/autoreplier/template/${this.currentMall.code}/${apiKeyId}/${templatedId}/delete/`({})

  //
  //
  // BUSINESS RULES METHODS
  //

  getBusinessReviewsRuleList = (sort: string, filter: BusinessReviewsRuleFilter) =>
    this.post<BusinessReviewsRule[]>`/autoreplier/rule/${this.currentMall.code}/list-all/`({
      sort,
      title: filter.title,
      productId: filter.productId,
      brandId: filter.brandId,
      templateId: filter.templateId,
      enabled: filter.enabled,
    })

  getBusinessReviewsRuleListForApiKey = (sort: string, filter: BusinessReviewsRuleFilter) =>
    this.post<
      BusinessReviewsRule[]
    >`/autoreplier/rule/${this.currentMall.code}/${filter.apiKeyId}/list/`({
      sort,
      title: filter.title,
      productId: filter.productId,
      brandId: filter.brandId,
      templateId: filter.templateId,
      enabled: filter.enabled,
    })

  createBusinessReviewsRule = (value: BusinessReviewsRule) =>
    this.post`/autoreplier/rule/${this.currentMall.code}/${value.apiKeyId}/create/`({
      title: value.title,
      productsId: value.productsId,
      brandsId: value.brandsId,
      templatesId: value.templatesId,
      accountableRate: value.accountableRate,
      enabled: value.enabled,
      replyToEmptyReviews: value.replyToEmptyReviews,
      replyToNonEmptyReviews: value.replyToNonEmptyReviews,
    })

  updateBusinessReviewsRule = (apiKeyId: number, value: BusinessReviewsRule) =>
    this.post`/autoreplier/rule/${this.currentMall.code}/${apiKeyId}/${value.id}/update/`({
      title: value.title,
      productsId: value.productsId,
      brandsId: value.brandsId,
      templatesId: value.templatesId,
      accountableRate: value.accountableRate,
      enabled: value.enabled,
      apiKeyId: value.apiKeyId,
      mall: this.currentMall.code,
      replyToEmptyReviews: value.replyToEmptyReviews,
      replyToNonEmptyReviews: value.replyToNonEmptyReviews,
    })

  daleteBusinessReviewsRule = (apiKeyId: number, ruledId: number) =>
    this.post`/autoreplier/rule/${this.currentMall.code}/${apiKeyId}/${ruledId}/delete/`({})

  enableBusinessReviewsRule = (apiKeyId: number, ruledId: number) =>
    this.post`/autoreplier/rule/${this.currentMall.code}/${apiKeyId}/${ruledId}/enable/`({})

  disableBusinessReviewsRule = (apiKeyId: number, ruledId: number) =>
    this.post`/autoreplier/rule/${this.currentMall.code}/${apiKeyId}/${ruledId}/disable/`({})

  //
  //
  // BUSINESS REVIEWS OTHER METHODS
  //

  getBusinessReviewsOnboarding = () =>
    this.get<BusinessReviewsOnboarding>`/storage/reviewsOnboarding/get/`

  setBusinessReviewsOnboarding = (value: any) =>
    this.post<BusinessReviewsOnboarding>`/storage/reviewsOnboarding/set/`({ ...value })

  //
  //
  // BUSINESS BIDDER METHODS
  //

  createBidderApiKey = (apiKeyId: number, bidderKey: string) =>
    this.post<BusinessApiKey>`/bidder/account/${this.currentMall.code}/${apiKeyId}/create-api-key/`(
      { key: bidderKey }
    )

  deleteBidderApiKey = (apiKeyId: number) =>
    this.post`/bidder/account/${this.currentMall.code}/${apiKeyId}/delete-api-key/`({})

  changeBidderApiKey = (apiKeyId: number, bidderKey: string) =>
    this.post<BusinessApiKey>`/bidder/account/${this.currentMall.code}/${apiKeyId}/update-api-key/`(
      {
        key: bidderKey,
      }
    )

  getBidderAccountInfo = (apiKeyId: number) =>
    this.get<BidderAccountInfo>`/bidder/account/${this.currentMall.code}/${apiKeyId}/info/`

  getBidderCampaignList = (filter: BidderCampaignListFilter, signal: AbortSignal) =>
    this.post<
      ListingDTO<BidderCampaignListItem> & BidderSyncResponse
    >`/bidder/campaign/${this.currentMall.code}/list/`(filter, { signal })

  getBidderCampaignInfo = (apiKeyId: number, campaignId: number) =>
    this
      .get<BidderCampaignInfo>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/info/`

  getBidderCampaignKeywords = (apiKeyId: number, campaignId: number) =>
    this
      .get<BidderCampaignKeywords>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/keywords/`

  syncBidderCampaignKeywords = (apiKeyId: number, campaignId: number) =>
    this
      .post<BidderSyncResponse>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/keywords/sync/`(
      {}
    )

  getBidderCampaignAvailableProducts = (apiKeyId: number, campaignId: number) =>
    this.get<
      BidderCampaignProduct[]
    >`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/products-to-add/`

  updateBidderCampaignKeywords = (
    apiKeyId: number,
    campaignId: number,
    update: BidderCampaignKeywordsUpdate
  ) =>
    this
      .post<BidderCampaignKeywords>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/keywords/update/`(
      update
    )

  updateBidderCampaignInfo = (
    apiKeyId: number,
    campaignId: number,
    update: BidderCampaignUpdateInfo
  ) =>
    this
      .post<BidderCampaignInfo>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/update/`(
      update
    )

  getBidderCampaignStat = (apiKeyId: number, campaignId: number) =>
    this.post<
      BidderCampaignStat[]
    >`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/stat/`({})

  getBidderCampaignStatSummary = (
    apiKeyId: number,
    campaignId: number,
    body: { from?: ISO8601DateString; to?: ISO8601DateString }
  ) =>
    this
      .post<BidderCampaignStatSummary>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/stat/summary/`(
      body
    )

  getBidderCampaignBidderStat = (
    apiKeyId: number,
    campaignId: number,
    body: { from?: ISO8601DateString; to?: ISO8601DateString }
  ) =>
    this.post<
      BidderCampaignBidderStat[]
    >`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/bidder-stat/`(body)

  getFeedBidDistribution = (
    request: BidderCampaignBidDistributionRequest,
    abortSignal?: AbortSignal
  ) =>
    this.post<
      BidderCampaignBidDistribution[]
    >`/bidder/feed/${this.currentMall.code}/feed-positions/`(request, {
      signal: abortSignal,
    })

  getBidderCampaignBidPrediction = (
    apiKeyId: number,
    campaignId: number,
    request: BidderCampaignBidDistributionRequest,
    targetPositions: number[],
    abortSignal?: AbortSignal
  ) =>
    this.post<
      BidderCampaignBidPrediction[]
    >`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/bid-predict/`(
      {
        ...request,
        targetPositions,
      },
      { signal: abortSignal }
    )

  getBidderRegions = () => this.get<BidderRegion[]>`/bidder/region/${this.currentMall.code}/list/`

  getFeedProductCurrentPosition = (
    request: BidderCampaignBidDistributionRequest & { sku: string }
  ) =>
    this
      .post<BidderProductCurrentPosition>`/bidder/feed/${this.currentMall.code}/product-position/`(
      request
    )

  syncBidderCampaignList = () =>
    this.post<BidderSyncResponse>`/bidder/campaign/${this.currentMall.code}/sync-all/`({})

  syncBidderCampaign = (apiKeyId: number, campaignId: number) =>
    this
      .post<BidderSyncResponse>`/bidder/campaign/${this.currentMall.code}/${apiKeyId}/${campaignId}/sync/`(
      {}
    )

  //
  //
  // BUSINESS SUPPLY RECOMMENDATION METHODS
  //

  getSupplyRecommendation = (request: SupplyRecommendationRequest) =>
    this
      .post<SupplyRecommendationReport>`/mall-analytics/supply/${this.currentMall.code}/recommendation/`(
      request
    )

  getSupplyRecommendationSettings = () =>
    this
      .get<SupplyRecommendationSettings>`/mall-analytics/supply/${this.currentMall.code}/settings/`

  getSupplyRecommendationAvailableSettings = () =>
    this
      .get<SupplyRecommendationAvailableSettings>`/mall-analytics/supply/${this.currentMall.code}/filters/`

  //
  //
  // WB COMMISSIONS DATA METHODS
  //

  getMarketplaceCommissions = () =>
    this.get<
      MarketplaceCommission[]
    >`/mall-analytics/unit-economics/${this.currentMall.code}/commissions/`

  getWarehouseCoefficients = () =>
    this.get<
      WarehouseCoefficient[]
    >`/mall-analytics/unit-economics/${this.currentMall.code}/warehouse-coefficients/`

  //
  //
  // AUTH METHODS
  //

  private authValidateStatus = (status: number) =>
    (status >= 200 && status < 300) || status === 400 || status === 429

  registerUser = (user: NewUser) =>
    this.post<RegisterResponse>`/register/`(user, {
      validateStatus: (status) => this.authValidateStatus(status),
    })

  registerSendAuthCode = (phone: string) =>
    this.post<RegisterSendAuthCodeResponse>`/register/send-auth-code/`(
      {
        phone,
      },
      {
        validateStatus: (status) => this.authValidateStatus(status),
      }
    )

  logIn = (loginData: LoginData) =>
    this.post<LoginResponse>`/auth/login/`(loginData, {
      validateStatus: (status) => this.authValidateStatus(status),
    })

  logInSubmitAuthCode = (authCode: string) =>
    this.post<LoginSubmitAuthCodeResponse>`/auth/two-factor/`(
      { authCode },
      {
        validateStatus: (status) => this.authValidateStatus(status),
      }
    )

  recoverySendAuthCode = (phone: string) =>
    this.post<RecoverySendAuthCodeResponse>`/recovery/send-reset-code/`(
      { phone },
      {
        validateStatus: (status) => this.authValidateStatus(status),
      }
    )

  recoveryResetPassword = (phone: string, authCode: string) =>
    this.post<RecoveryResetPasswordResponse>`/recovery/reset-password/`(
      { authCode, phone },
      {
        validateStatus: (status) => this.authValidateStatus(status),
      }
    )

  getPromoCodeSignup = (code: string) =>
    this.get<PromoCode | []>`/register/promo-code/?promoCode=${code}`

  getPromoCodeSubscription = (code: string) =>
    this.get<PromoCode | []>`/subscription/promo-code/?promoCode=${code}`

  getDefaultTrialDaysSignup = () => this.get<number>`/register/default-trial-days/`

  getDefaultTrialDaysSubscription = () => this.get<number>`/subscription/default-trial-days/`

  testEmail = (email: string) => this.post<'taken' | 'available'>`/register/test-email/`({ email })

  testPhone = (phone: string) => this.post<'taken' | 'available'>`/register/test-phone/`({ phone })

  // Landing methods

  requestDemo = (request: CompanyContactRequest) =>
    this.post<{ status: 'success' }>`/landing/demo/`({ ...request })

  requestInstallment = (request: PersonContactRequest) =>
    this.post<{ status: 'success' | 'error' }>`/landing/installment/`(request)

  requestConsulting = (request: PersonContactRequest) =>
    this.post<{ status: 'success' | 'error' }>`/landing/consulting/`(request)

  getSellematicsArticles = (page: number, pageSize: number, lang: 'ru' | 'en') =>
    this.get<BLArticleList>`/articles/list/${lang}/?page=${page}&pageSize=${pageSize}`

  getSellematicsArticleBySlug = (slug: string) => this.get<BLArticle>`/articles/detail/${slug}/`

  // AI methods

  getAiFeatureTimeout = (mall: string, feature: AiFeatureName) =>
    this.post<{ seconds: number }>`/ai/${mall}/allow/${feature}/`({})

  generateProductDescription = (options: {
    productName: string
    keywords: string[]
    mall: string
    marketplace: string
    language: 'en' | 'ru'
  }) => this.post<{ completion: string; seconds: number }>`/generate-product-description/`(options)

  // SEO-optimization

  getSimilarProductsSearchQueries = (productId: number) =>
    this.get<
      Feed[]
    >`/seo-optimization/${this.currentMall.code}/product/${productId}/search-queries/`

  startSimilarProductsSearchQueriesExport = (productId: number) =>
    this
      .post<StartExportResponse>`/seo-optimization/${this.currentMall.code}/product/${productId}/search-queries/export/`(
      {}
    )

  trackPrmEvent = () => this.get`/fe083d426e72af1023692fea8d533fbf/`

  trackActionpayActionClient = (action: ActionpayAction, actionId: string, price: number) =>
    this.post`/bc8b7e7eba0850d50c46d497dba51ae0/`({
      action: actionpayGoalIds[action],
      actionId,
      price,
    })
}
