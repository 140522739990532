import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { c, FC } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { useIntl } from 'lib/intl-utils'
import { useEffect, useState } from 'react'
import { TelegramIcon, WhatsAppIcon, YouTubeIcon } from 'components/icons'
import { useRouter } from 'next/router'
import Link from 'components/base/Link'
import { useTrackGoal } from 'lib/metrics'
import Tooltip from 'components/base/Tooltip'

const contactLinks = [
  {
    name: 'Telegram',
    prefix: 'https://t.me/',
    href: (c: any) => c.telegram,
    icon: TelegramIcon,
  },
  { name: 'WhatsApp', prefix: 'https://wa.me/', href: (c: any) => c.whatsapp, icon: WhatsAppIcon },
  {
    name: 'Email',
    prefix: 'mailto:',
    href: (c: any) => c.mail,
    icon: MailIcon,
  },
  {
    name: 'Phone',
    prefix: 'tel:',
    href: (c: any) => c.phone,
    icon: PhoneIcon,
  },
]

const socialLinks = [
  {
    name: 'Telegram',
    prefix: 'https://t.me/',
    href: (c: any) => 'sellmonitor_com',
    icon: TelegramIcon,
  },
  {
    name: 'YouTube',
    prefix: 'https://youtube.com/',
    href: (c: any) => c.youtube,
    icon: YouTubeIcon,
  },
]

const navigation = [
  {
    name: 'Сервис',
    links: [
      // { name: 'Тарифы', href: '/pricing/' },
      {
        name: 'Расширение',
        href: 'https://chrome.google.com/webstore/detail/sellmonitor/nlldhlipbgolknjpcihjdobjjngeejag',
      },
      { name: 'Telegram-бот', href: 'https://t.me/sellmonitor_info_bot' },
      { name: 'Рассрочка', href: '/installment-plan/' },
      { name: 'Сопровождение', href: '/consulting/' },
    ],
  },
  {
    name: 'Обучение',
    links: [
      { name: 'База знаний', href: '/help/knowledge-base/' },
      { name: 'Бесплатные вебинары', href: 'https://webinar.sellmonitor.com/' },
    ],
  },
  {
    name: 'О компании',
    links: [
      { name: 'О нас', href: '/about/' },
      { name: 'Контакты', href: '/contacts/' },
      // { name: 'Вакансии', href: '/careers/' },
      // { name: 'Для прессы', href: '/press/' },
    ],
  },
]

const SellmonitorFooter: FC = ({ className }) => {
  const { t, lang } = useIntl()
  const { theme } = useUserContext()
  const router = useRouter()
  const trackGoal = useTrackGoal()
  const [hide, setHide] = useState(false)

  useEffect(() => {
    const iframe = document.querySelector('main > div > iframe')

    if (iframe !== null) {
      setHide(true)
    } else {
      setHide(false)
    }
  }, [router.asPath])

  const currentYear = new Date().getFullYear()
  const lifespan =
    theme.releaseDate !== currentYear ? theme.releaseDate + '–' + currentYear : theme.releaseDate

  if (hide) return null

  return (
    <footer className="bg-white border-t border-gray-200" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-4 xl:gap-8">
          <div className="space-y-4 xl:col-span-2">
            <div>
              <img src={theme.logoDark} className={c`w-32`} alt={`${theme.title} logo`} />
              <div className="mt-1 text-xs text-gray-700 [font-weight:480]">
                powered by Sellematics
              </div>
            </div>
            <div className="grid grid-cols-3 gap-3 w-fit sm:grid-cols-5">
              <Tooltip
                sizing="text"
                content={
                  <div>
                    <div className="font-medium">Авторизованный сервис аналитики Wildberries</div>
                    <div className="mt-0.5">
                      Это значит, что Sellmonitor прошел проверку на соответствие требованиям при
                      работе с личным кабинетом продавца. Можно смело подключаться — Wildberries
                      гарантирует безопасность и соответствие правилам маркетплейса.
                    </div>
                  </div>
                }
              >
                <a
                  href="https://seller.wildberries.ru/auth-services"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/next/img/footer/wb_partner.svg" className="size-[80px]" alt="" />
                </a>
              </Tooltip>
              <Tooltip
                sizing="text"
                content={
                  <div>
                    <div className="font-medium">
                      Сертифицированное рекламное агентство Wildberries
                    </div>
                    <div className="mt-0.5">
                      Услуги Sellmonitor по продвижению и сопровождению магазинов на Wildberries
                      прошли проверку на соответствие критериям качества. Наши специалисты прошли
                      тест на подтверждение квалификации, а наши успешные кейсы были верифицированы
                      маркетплейсом.
                    </div>
                  </div>
                }
              >
                <a
                  href="https://cmp.wildberries.ru/landings/certification"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/next/img/footer/wb_silver.svg" className="size-[80px]" alt="" />
                </a>
              </Tooltip>
              <Tooltip
                sizing="text"
                content={
                  <div>
                    <div className="font-medium">Сервис-партнёр Ozon</div>
                    <div className="mt-0.5">
                      Получаем от Ozon официальные данные по внешней аналитике — показываем сведения
                      о продажах и выручке с высокой точностью.
                    </div>
                  </div>
                }
              >
                <img src="/next/img/footer/ozon_partner.svg" className="size-[80px]" alt="" />
              </Tooltip>
              <Tooltip
                sizing="text"
                content={
                  <div>
                    <div className="font-medium">Партнёр Яндекс Маркета</div>
                    <div className="mt-0.5">
                      Sellmonitor — партнёр Яндекс Маркета. Наши данные по маркетплейсу самые точные
                      и актуальные.
                    </div>
                  </div>
                }
              >
                <a
                  href="https://partner.market.yandex.ru/welcome/partners-page"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/next/img/footer/yandex_partner.svg" className="size-[80px]" alt="" />
                </a>
              </Tooltip>
              <Tooltip
                sizing="text"
                content={
                  <div>
                    <div className="font-medium">Отечественное ПО</div>
                    <div className="mt-0.5">
                      Наша компания аккредитована в Минцифры, а сервис Sellmonitor входит в реестр
                      отечественного ПО. Эксперты министерства проверили соответствие сервиса ряду
                      критериев, к примеру, по информационной безопасности и обеспечению поддержки
                      пользователей.
                    </div>
                  </div>
                }
              >
                <a
                  href="https://reestr.digital.gov.ru/reestr/2100122/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/next/img/footer/mincifri.svg" className="size-[80px]" alt="" />
                </a>
              </Tooltip>
            </div>
            <div className="flex items-center space-x-8 sm:space-x-12 !mt-8">
              <div className="space-y-2">
                <div className="text-[13px] font-medium text-gray-700">Свяжитесь с нами</div>
                <div className="flex space-x-4">
                  {contactLinks.map((item) => {
                    const onClick =
                      item.name === 'YouTube'
                        ? () => trackGoal('moved-to-youtube')
                        : item.name === 'Telegram'
                        ? () => trackGoal('moved-to-messenger')
                        : () => {}
                    const link = item.href(theme.contactInformation)
                    if (!link) {
                      return null
                    }
                    return (
                      <a
                        key={item.name}
                        href={item.prefix + link}
                        target="_blank"
                        rel="noreferrer"
                        onClick={onClick}
                        className="flex justify-center items-center px-2 py-1 rounded-md aspect-1 group text-primary-600 bg-primary-50 hover:bg-primary-600 hover:text-primary-50"
                      >
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="w-6 h-6" aria-hidden="true" />
                      </a>
                    )
                  })}
                </div>
              </div>
              <div className="space-y-2">
                <div className="text-[13px] font-medium text-gray-700">Следите за новостями</div>
                <div className="flex space-x-4">
                  {socialLinks.map((item) => {
                    const onClick =
                      item.name === 'YouTube'
                        ? () => trackGoal('moved-to-youtube')
                        : item.name === 'Telegram'
                        ? () => trackGoal('moved-to-messenger')
                        : () => {}
                    const link = item.href(theme.contactInformation)
                    if (!link) {
                      return null
                    }
                    return (
                      <a
                        key={item.name}
                        href={item.prefix + link}
                        target="_blank"
                        rel="noreferrer"
                        onClick={onClick}
                        className="flex justify-center items-center px-2 py-1 text-cyan-600 bg-cyan-50 rounded-md aspect-1 group hover:bg-cyan-600 hover:text-cyan-50"
                      >
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="w-6 h-6" aria-hidden="true" />
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 grid-rows-2 gap-8 mt-12 xl:col-span-2 xl:mt-0">
            {navigation.map((category) => (
              <div key={category.name}>
                <h3 className="text-base font-medium text-gray-900">{category.name}</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {category.links.map((link) => {
                    const onClick =
                      link.name === 'Telegram-бот'
                        ? () => trackGoal('moved-to-telegram-bot')
                        : () => {}
                    const isInternal = link.href.startsWith('/')
                    const LinkComponent = isInternal ? Link : 'a'
                    const linkProps = isInternal ? {} : { target: '_blank', rel: 'noreferrer' }

                    return (
                      <li key={link.name}>
                        <LinkComponent
                          {...linkProps}
                          href={link.href}
                          onClick={onClick}
                          className="text-base text-gray-500 hover:text-gray-900"
                        >
                          {link.name}
                        </LinkComponent>
                      </li>
                    )
                  })}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-baseline pt-8 space-y-4 border-t border-gray-200 lg:flex-row">
          <div>
            {lang === 'en' && (
              <p className="text-base text-gray-400">
                &copy; {lifespan} Sellematics. All rights reserved.
              </p>
            )}
            {lang === 'ru' && (
              <p className="text-base text-gray-400">
                &copy; Sellematics, {lifespan}. Все права защищены.
              </p>
            )}
          </div>
          <div className="flex flex-col space-y-2 text-gray-400 lg:space-x-6 lg:space-y-0 lg:flex-row lg:ml-auto">
            <a className="hover:text-gray-500" href="/legal/">
              {t`footer.legal_information`}
            </a>
            <a className="hover:text-gray-500" href="/doc/sellmonitor/ru/public_offer.pdf">
              {t`footer.public_offer`}
            </a>
            <a className="hover:text-gray-500" href="/doc/sellmonitor/ru/privacy_policy.pdf">
              {t`footer.privacy_policy`}
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default SellmonitorFooter
